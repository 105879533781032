import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import ValuesLinks from "../components/ValuesLinks";
import ScrollAnimation from "../components/ScrollAnimation";
import { Helmet } from "react-helmet";

const title = "Răscumpărare";

const RascumpararePage: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    headlines {
                        rascumparare
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="image-bordered">
                                <StaticImage
                                    src="../images/rascumparare.jpg"
                                    alt={title}
                                />
                            </div>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h3 className="headline">{data.site.siteMetadata.headlines.rascumparare}</h3>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <blockquote className="blockquote">
                                    <p>&quot;Lăudaţi pe Domnul, chemaţi Numele Lui! Faceţi cunoscute printre popoare isprăvile Lui! Cântaţi, cântaţi în cinstea Lui! Vorbiţi despre toate minunile Lui!&quot;</p>
                                    <footer>Psalmul 105:1-2</footer>
                                </blockquote>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Câteva dintre lucrurile pe care le facem pentru a duce vestea bună a salvării altora sunt:</p>
                                <ul>
                                    <li><strong>Arka Kids</strong></li>
                                    <li><strong>Arka 10agers</strong></li>
                                    <li><strong>Arka Young</strong></li>
                                    <li><strong>Arka Fotbal</strong></li>
                                    <li><strong>Centrele de plasament Antonio și Ovidiu</strong>: relaționare și mentorare</li>
                                    <li><strong>After school</strong>: teme și activități pentru copiii din mediile defavorizate</li>
                                    <li><strong>Persoanele cu dizabilități</strong>: competiții sportive, întâlniri periodice de socializare</li>
                                </ul>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ValuesLinks active={2} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default RascumpararePage;
